import gsap from "gsap";

require("./scripts/modernizr");

/**
 * webgl background
 */
// require("./script.shader");
// require("./script.object");
// require("./script.test");

// require("./script.prod-v1");
require("./script.prod");

/**
 * enter effect
 */
function enterEffect() {
  const tl = gsap.timeline({ paused: true });

  tl.to(".overlay", {
    duration: 1,
    opacity: 0,
    ease: "power4",
  });

  tl.set(".overlay", { visibility: "hidden" });

  tl.fromTo(
    ".onebox",
    {
      opacity: 0,
      scale: 0.8,
      x: "-50%",
      y: "-50%",
    },
    {
      duration: 2,
      opacity: 1,
      scale: 1,
      x: "-50%",
      y: "-50%",
      ease: "power4.out",
    }
  );
  tl.fromTo(
    ".line",
    {
      y: "-105%",
    },
    {
      duration: 2,
      y: "105%",
      ease: "power4.inOut",
    },
    "-=1.5"
  );
  tl.from(
    ".onepage",
    {
      duration: 1.5,
      opacity: 0,
      scale: 0.9,
      x: "25%",
      ease: "power4.out",
    },
    "-=0.7"
  );
  tl.from(
    ".xelected",
    {
      duration: 1.5,
      opacity: 0,
      scale: 0.9,
      x: "0%",
      ease: "power4.out",
    },
    "-=1.3"
  );
  tl.from(
    ".auau",
    {
      duration: 1.5,
      opacity: 0,
      scale: 0.9,
      x: "-25%",
      ease: "power4.out",
    },
    "-=1.3"
  );

  setTimeout(() => {
    tl.play();
  }, 500);
}

/**
 * cursor
 */
function cursor() {
  // const $circle = document.querySelector(".circle");
  const $follow = document.querySelector(".circle-follow");

  function moveCircle(e) {
    // gsap.to($circle, 0.3, {
    //   x: e.clientX,
    //   y: e.clientY,
    // });
    gsap.to($follow, 0.3, {
      x: e.clientX,
      y: e.clientY,
    });
  }

  function hoverFunc(e) {
    // gsap.to($circle, 0.3, {
    //   opacity: 1,
    //   scale: 0,
    // });
    gsap.to($follow, 0.3, {
      // scale: 1.2,
      width: "80px",
      height: "80px",
    });
  }

  function unhoverFunc(e) {
    // gsap.to($circle, 0.3, {
    //   opacity: 1,
    //   scale: 1,
    // });
    gsap.to($follow, 0.3, {
      // scale: 1,
      width: "30px",
      height: "30px",
    });
  }

  window.addEventListener("mousemove", moveCircle);

  const links = document.querySelectorAll("a");

  links.forEach((link) => {
    link.addEventListener("mouseenter", hoverFunc);
    link.addEventListener("mouseleave", unhoverFunc);
  });
}

document.addEventListener("DOMContentLoaded", (event) => {
  enterEffect();
  cursor();
});
