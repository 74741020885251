import "./style.css";
import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js";
// import * as dat from "dat.gui";
import { hexToVec } from "./scripts/utils";
import gsap from "gsap";

// constants
const aspectMobile = 780 / 1020;

// const fov = 40;
const fov = 30;
const aspect = window.innerWidth / window.innerHeight;
const near = 1;
const far = 1000;

const COLORS = [
  "#fbbecc",
  "#c17ade",
  "#e48fd7",
  "#b4ecd0",
  "#e8e0ca",
  "#f4ceca",
  "#ccc2db",
  "#f5abce",
  "#8bd7e3",
  "#9c80ea",
  "#9fc4e6",
  "#d0e9ca",
  "#889aee",
  "#9ee7d7",
  "#82baeb",
];
const MAX_COUNT = 80;

// Debug
// const gui = new dat.GUI();

// Loader
// const loader = new THREE.FileLoader();

// Canvas
const canvas = document.querySelector("canvas.webgl");

// Scene
const scene = new THREE.Scene();

const geometries = [];
const uniforms = [];
const materials = [];
const mesh = [];

let color_key = 0;
for (let i = 0; i < MAX_COUNT; i++) {
  if (color_key >= COLORS.length - 1) {
    color_key = 0;
  } else {
    color_key++;
  }

  // Objects
  // geometries.push(new THREE.TorusGeometry(10, 3, 16, 100));
  geometries.push(new THREE.CylinderGeometry(30, 30, 0.005, 200, 1, true));

  // Materials
  uniforms.push({
    color: {
      type: "v3",
      value: hexToVec(COLORS[color_key]),
    },
    time: {
      type: "f",
      value: 0.0 + i,
    },
  });

  materials.push(
    // new THREE.MeshBasicMaterial({ color: 0xffff00 })
    new THREE.ShaderMaterial({
      wireframe: true,
      uniforms: uniforms[i],
      vertexShader: require("./shaders/prod/vertex.vert").default,
      fragmentShader: require("./shaders/prod/fragment.frag").default,
    })
  );
  materials[i].side = THREE.DoubleSide;

  // Mesh
  mesh.push(new THREE.Mesh(geometries[i], materials[i]));
  // mesh[i].rotation.x = 90;
  // mesh[i].position.z = i;
  // mesh[i].scale.x = 1 - i / 100;
  // mesh[i].scale.z = 1 - i / 100;
  // mesh[i].rotation.x = Math.random(0, i) / 100;

  // mesh[i].position.y = i / 1.2;
  // mesh[i].position.z = i;

  // let scale = 1;
  // if (i > 40) {
  //   scale = 1 - i / 150;
  // } else {
  //   scale = 1 - i / 150;

  //   // scale = 1 + i / 150;
  //   // scale = 1 - (MAX_COUNT - i) / 150;
  // }

  // const scale = 1 - i / 110;
  // mesh[i].scale.x = scale;
  // mesh[i].scale.z = scale;

  mesh[i].rotation.y = Math.random(0, i) / 100;

  // mesh[i].position.y = i;
  // mesh[i].scale.x = 1 + i / 1000;
  // mesh[i].scale.z = 1 + i / 1000;
  // mesh[i].rotation.y = Math.random(0, i) / 100;

  scene.add(mesh[i]);
}

// Lights
// const pointLight = new THREE.PointLight(0xffffff, 0.1);
// pointLight.position.x = 2;
// pointLight.position.y = 3;
// pointLight.position.z = 4;
// scene.add(pointLight);

/**
 * Sizes
 */
const sizes = {
  width: window.innerWidth,
  height: window.innerHeight,
};

window.addEventListener("resize", () => {
  // Update sizes
  sizes.width = window.innerWidth;
  sizes.height = window.innerHeight;

  // Update camera
  camera.aspect = sizes.width / sizes.height;

  if (camera.aspect < aspectMobile) {
    camera.fov = 40;
  } else {
    camera.fov = 30;
  }

  camera.updateProjectionMatrix();

  // Update renderer
  renderer.setSize(sizes.width, sizes.height);
  renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
});

/**
 * Camera
 */
// Base camera
const camera = new THREE.PerspectiveCamera(fov, aspect, near, far);
camera.position.y = 90;
camera.position.z = 0;

scene.add(camera);

window.addEventListener("mousemove", (e) => {
  // const x = e.clientX;
  // const y = e.clientY;
  const x = (window.innerWidth / 2 - e.clientX) * -1;
  const y = window.innerHeight / 2 - e.clientY;

  // console.log(x, y);

  // camera.position.x = 0 + x / 10;
  // camera.position.y = 90 + y / 10;
  // camera.rotation.z = 0 + y / 10;

  for (let i = 0; i < MAX_COUNT; i++) {
    // mesh[i].rotation.z = (-1 * x) / 5000;
    // mesh[i].rotation.x = (-1 * y) / 5000;

    gsap.to(mesh[i].rotation, {
      duration: 1,
      z: (-1 * x) / 5000,
      ease: "power2",
    });
    gsap.to(mesh[i].rotation, {
      duration: 1,
      x: (-1 * y) / 5000,
      ease: "power2",
    });
  }
});

// Controls
const controls = new OrbitControls(camera, canvas);
controls.enableDamping = true;

/**
 * Renderer
 */
const renderer = new THREE.WebGLRenderer({
  canvas: canvas,
  alpha: true,
});
renderer.setSize(sizes.width, sizes.height);
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
renderer.setClearColor(0x000000, 0);

/**
 * Animate
 */

const clock = new THREE.Clock();

const tick = () => {
  const elapsedTime = clock.getElapsedTime();

  // Update objects
  // mesh.rotation.y = 0.5 * elapsedTime;
  for (let i = 0; i < MAX_COUNT; i++) {
    uniforms[i].time.value = 0.1 * elapsedTime + i / 90;
  }
  // uniforms.resolution.value.set(canvas.width, canvas.height, 1);

  // Update Orbital Controls
  controls.update();

  // Render
  renderer.render(scene, camera);

  // Call tick again on the next frame
  window.requestAnimationFrame(tick);
};

tick();
